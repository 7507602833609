<template>
  <div class="Content-Page">
    <div class="N-Page T-size-36">Manage Company Sync</div>

    <div class="B-btn">
      <v-btn class="theme-btn-even" @click.stop="addItem()">
        <v-icon>mdi-plus</v-icon>Add
      </v-btn>
    </div>
    <ejs-grid
      :dataSource="items"
      :searchSettings="searchOptions"
      :toolbar="toolbarOptions"
      :allowGrouping="true"
      :allowExcelExport="true"
    >
      <e-columns>
        <e-column field="Code" headerText="Customer ID" width="120"></e-column>
        <e-column field="Name" headerText="Ship City" width="100"></e-column>
        <e-column field="AlcoholLimit" headerText="Ship Name" width="100"></e-column>
        <e-column field="TemperatureLimit" headerText="Ship Name" width="100"></e-column>
        <e-column headerText="Ship Name" width="100">fdfsd</e-column>
      </e-columns>
    </ejs-grid>
  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import {
  GridPlugin,
  Page,
  Sort,
  Group,
  Toolbar,
  Search,
  ExcelExport
} from "@syncfusion/ej2-vue-grids";
export default {
  data: () => ({
    toolbarOptions: ["Search", "ExcelExport"],
    searchOptions: {
      fields: ["Name"],
      operator: "contains",
      key: "",
      ignoreCase: true
    },
    report1: null,
    defaultValue: {
      Name: ""
    },
    query: { sort: { Id: -1 } },
    //--end config

    items: [], // data ที่มาจากการ find ของ server
    total: 0,
    inDTO: {}, // data ที่มาจากการ get ของ server
    outDTO: {}, // data ที่เป็น Object ที่จะส่งไป create หรือ update ที่ server
    loading: false,
    dialog: false,
    dialogDelete: false,
    formModel: {},
    mode: "" // มีได้ 2 แบบคือ create กับ edit
  }),
  provide: {
    grid: [Page, Sort, Group, Toolbar, ExcelExport]
  },
  computed: {
    formTitle() {
      return this.mode === "create"
        ? "Add " + this.objectName
        : "Edit " + this.objectName;
    }
  },
  async mounted() {
    //init here
    // const userlogin = this.$store.state.auth.user;
    // alert(JSON.stringify(userlogin))
    this.renderUI();
  },
  methods: {
    toolbarClickReport1(args) {
      if (args.item.id === "GridReport1_excelexport") {
        // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.gridReport1.excelExport();
      }
    },
    async renderUI() {
      try {
        var res = await feathersClientUOA.service("company").find(this.query);
        this.total = res.total;
        this.items = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async addItem() {
      this.mode = "create";
      this.formModel = Object.assign({}, this.defaultValue);
      this.dialog = true;
    },

    async editItem(item) {
      this.mode = "edit";
      this.formModel = Object.assign({}, item);
      this.dialog = true;
    },
    async deleteItem(item) {
      this.formModel = Object.assign({}, item);
      this.dialogDelete = true;
    },

    closeDialog() {
      this.dialog = false;
      this.dialogDelete = false;
    },
    async saveToServer() {
      // const valid = await this.$validator.validateAll();
      // if (!valid) {
      //   alert("กรุณากรอกข้อมูลให้สมบรูณ์");
      //   return;
      // }
      this.loading = true;
      if (this.mode === "edit") {
        try {
          this.outDTO = Object.assign({}, this.formModel);
          // hook ที่จะแก้ข้อมูลก่อนส่งไป server ใส่ที่นี้

          await await feathersClientUOA
            .service("company")
            .patch(this.formModel.Id, this.outDTO);
          this.renderUI();
        } catch (err) {
          console.log(err);
          alert("ไม่สามารถแก้ไขข้อมูลได้");
        } finally {
          this.loading = false;
        }
      } else {
        //Add Data
        try {
          this.outDTO = Object.assign({}, this.formModel);
          // hook ที่จะแก้ข้อมูลก่อนส่งไป server ใส่ที่นี้

          await feathersClientUOA.service("company").create(this.outDTO);
          this.renderUI();
        } catch (err) {
          console.log(err);
          alert("ไม่สามารถเพิ่มข้อมูลได้");
        } finally {
          this.loading = false;
        }
      }
      this.closeDialog();
    },
    async deleteToServer() {
      this.loading = true;
      try {
        await feathersClientUOA.service("company").patch(this.formModel.Id);
        this.renderUI();
      } catch (err) {
        console.log(err);
        alert("ไม่สามารถลบข้อมูลได้");
      } finally {
        this.loading = false;
        this.dialogDelete = false;
      }
    }
  }
};
</script>